import React from "react";
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import ProfilCard from "../../components/profil-card";

const ProfiliPoljoprivreda = () => {
    return (
        <>
            <div className="container mt-5">
                <h1 className="title is-3">Profili za poljoprivredne stranice</h1>
                <hr/>
            </div>

            <div className="container">
                <ProfilCard title="Profil 1" lPic="stranica-1-s.jpg" rPic="stranica-1.jpg"/>
                <ProfilCard title="Profil 2" lPic="stranica-2-s.jpg" rPic="stranica-2.jpg"/>
                <ProfilCard title="Profil 3" lPic="stranica-3-s.jpg" rPic="stranica-3.jpg"/>
            </div>
        </>
    )
}

export default ProfiliPoljoprivreda
